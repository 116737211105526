import {
  Chart,
  LineSeries,
  Category,
  Tooltip,
  Legend,
  DataLabel,
} from "@syncfusion/ej2-charts";
import { registerLicense } from "@syncfusion/ej2-base";

// Injetando os módulos necessários para o gráfico
Chart.Inject(LineSeries, Category, Tooltip, Legend, DataLabel);

class BurndownChart {
  constructor({ wrapper, data, title = "Gráfico de Burndown", data_inicial, data2 }) {
    // Registrando a licença Syncfusion
    registerLicense(
      "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
    );
    console.log("Licença Syncfusion registrada com sucesso.");

    try {
      if (!wrapper) {
        throw new Error("É necessário fornecer um wrapper para inicializar o Gráfico.");
      }

      this.$wrapper = $(wrapper);

      // Transformação dos dados
      const data1 = data.map((item) => ({
        Data: item.Data,
        Ideal: item.percentageOfHours,
        Realizado: 0

      }));
      let percentage = 100;

      const dataa2 = data2.map((item) => ({
        Data: item.Data,
        Ideal: 0,
        Realizado: item.percentageOfHours,
      }));

      
      const dataFull = [...data1, ...dataa2];
      console.log("dataFull:", dataFull);
      const sortedData = dataFull.sort((a, b) => new Date(a.Data) - new Date(b.Data));
      
      sortedData.unshift({ Data: data_inicial, Ideal: percentage, Realizado: 100 });
      // dataa2.unshift({ Data: data_inicial, Ideal: percentage , Realizado: 100 });
      
      console.log("sortedData",sortedData);
      console.log("Dados do gráfico de Burndown2:", dataa2);
      let lastRealizado = 0;
      let lastIdeal = 0;

      const groupedData = {};

sortedData.forEach((item) => {
    if (!groupedData[item.Data] || item.Realizado !== groupedData[item.Data].Realizado) {
        groupedData[item.Data] = item;
    }
});

const updatedData = Object.values(groupedData).map((item) => {
    if (item.Realizado === 0) {
        item.Realizado =100 - lastRealizado; // Preenche com o último valor válido
    } else {
        lastRealizado =100 - item.Realizado; // Atualiza o último valor válido
    }

    if (item.Ideal === 0) {
        item.Ideal = lastIdeal; // Preenche com o último valor válido
    } else {
        lastIdeal = item.Ideal; // Atualiza o último valor válido
    }

    return item;
});


console.log("vinvinvin",updatedData);

      // Criando o gráfico de Burndown
      const lineGrid = new Chart({
        primaryXAxis: {
          title: "Data de Entrega",
          valueType: "Category",
          interval: 1,
          labelPlacement: "OnTicks",
          
        },
        primaryYAxis: {
          title: "Progresso (%)",
          minimum: 0,
          maximum: 100,
          interval: 20,
        },
        series: [
          {
            dataSource: updatedData,
            xName: "Data",
            yName: "Ideal",
            name: "Ideal",
            type: "Line",
            marker: { visible: true },
          },{
            dataSource: updatedData,
            xName: "Data",
            yName: "Realizado",
            name: "Realizado",
            type: "Line",
            marker: { visible: true },
          },
        ],
        title,
        tooltip: { enable: true },
        legendSettings: { visible: true },
      });

      // Renderizando o gráfico no elemento
      lineGrid.appendTo(this.$wrapper[0]);
      console.log("Gráfico de Burndown renderizado com sucesso.");
    } catch (e) {
      console.error("Erro ao inicializar o Gráfico de Burndown:", e);
    }
  }
}

frappe.provide("frappe.burndown");
frappe.burndown.BurndownChart = BurndownChart;

export default BurndownChart;
